.mainDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text{
    /* margin-left: 10%; */
    font-size: 50px;
    font-weight: 500;
    color: #0f3f7d;
}