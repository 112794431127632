.tie {
    color:#7e7e7e;
    cursor: grab;
    list-style: none;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px dashed #7e7e7e;
    font-size: 13px;
    box-shadow: 0 3px 6px #efefef;
}

.tie:hover {
    box-shadow: 0 3px 6px #888;
}


.app{
    text-align: center;
    max-width: 500px;
    margin:100px auto;
  }
  .input-group{
    display: flex;
    flex-direction: column;
  }
  .input-group input{
    padding:15px;
  }
/*   
  .btn{
    background-color: #888CF0;
    color:#000000;
    margin-top: 20px;
    box-shadow: none;
    border:none;
    padding:15px;
    cursor: pointer;
    font-size: 20px;
  } */
  
  .list-container{
    margin-top:40px;
  }
  .list-item{
    display:flex;
    align-items: center;
    background-color: #FFD2D2;
    padding:0px 20px;
    margin-top:15px;
    cursor:move;
    
  }
  .list-item i{
    margin-right:20px;
    font-size: 30px;
  }
  h3{
    font-size: 30px;
  }

.dragHere{
    border:1px solid red;
    min-height: 150px;
}

.drag-over {
    border: dashed 3px red;
}

.hide {
    display: none;
}