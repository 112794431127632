.skeleton-wrapper {
    margin: 10px;
}

.skeleton {
    margin-top: 5px;
    width: 100%;
    height: 75px;
    /*
     background-image: linear-gradient(90deg, #e2e2e2 0px, #efefef 30px, #e2e2e2 60px);
     background-size: calc(160px + 160px);
     animation: refresh 1.2s infinite ease-out;
     */
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    -webkit-animation: shine 1s ease infinite;
    animation: shine 1s ease infinite;
}

@keyframes refresh {
    0% {
        background-position: calc(-160px);
    }
    60%,
    100% {
        background-position: 160px;
    }
}

@-webkit-keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

.skeleton.small {
    /* width: 160px; */
    height: 20px;
    width: 10%;
}

.mid60 {
    width: 60%;
    height: 20px;
}

.mid25 {
    width: 25%;
    height: 20px;
}

.mid20 {
    width: 20%!important;
    height: 35px!important;
}

.midHeader100 {
    width: 100%!important;
    height: 35px!important;
}

.mid100 {
    width: 100%;
    height: 40px;
}

.submitBtn {
    width: 6%;
    height: 35px;
}

.skeleton.mid50 {
    width: 50%;
    height: 20px;
}

.skeleton.mid75 {
    width: 75%;
    height: 20px;
}

.skeleton.round {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.labelWidth {
    width: 7%;
    height: 25px;
}