:root {
    /* --btn-color: #343a40;
    --btn-color: #fb641b; */
    --btn-color: #0f3f7d;
    --theme-color-primary: #0f3f7d;
    --theme-color-secondary: #e1f4fb;
}

html,
body {
    font-family: 'Montserrat', sans-serif;
    font-family: 'Open Sans', sans-serif;
}

button:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

[class*=sidebar-light-] {
    background-color: var(--theme-color-secondary) !important;
}

.btn-theme-primary {
    background-color: var(--btn-color) !important;
    color: #fff;
}

.theme-text-color-primary {
    color: var(--theme-color-primary) !important;
}
.theme-text-color-secondary {
    color: var(--theme-color-secondary) !important;
}


/* .btn-theme-primary:hover {
    color: var(--theme-color-primary) !important;
    background-color: var(--theme-color-secondary) !important;
    border: 1px solid var(--theme-color-primary);
} */

.btn-theme-primary:hover {
    background-color: #0f3f7d!important;
    color: #fff!important;
}

.card {
    border-top: 3px solid var(--btn-color);
    border-color: var(--btn-color);
}

#nprogress .bar {
    height: 3px !important;
    z-index: 10319 !important;
}

#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 10316 !important;
    top: 15px !important;
    right: 15px !important;
}

.nav-sidebar>.nav-item {
    margin-bottom: 0;
    cursor: pointer;
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 700 !important;
}

.card-body-table {
    padding: 0 !important;
}

.content-wrapper>.content {
    padding: 0 1rem !important;
}

label {
    display: inline-block;
    margin-bottom: 0.1rem !important;
}

.d-menu-block {
    transition: all 2s linear;
    display: block;
}

.brand-text-color {
    color: var(--theme-color-primary);
}

.nav-pills .nav-link:not(.active):hover {
    padding-left: 25px;
    transition: padding ease-in-out .35s;
}

th {
    padding: 5px 6px !important;
    text-transform: uppercase;
    color: var(--theme-color-primary) !important;
    font-weight: bold !important;
    opacity: 1 !important;
    /* white-space: nowrap !important; */
}
th>span {
    font-size: 15px;
    font-weight: 600
}

td {
    padding-right:6px !important;
    padding-left:6px !important;
    font-size: 16px !important; 
    white-space: nowrap !important;
}


/* 
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
    padding: 10px 16px;
} */

.navbar-navy {
    color: #fff;
    background-color: var(--theme-color-primary);
}

.sidebar-dark-navy .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-navy .nav-sidebar>.nav-item>.nav-link.active {
    background-color: var(--theme-color-primary);
    color: #fff;
}

[class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link.active,
[class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link.active:hover {
    background-color: var(--theme-color-primary);
    color: #fff;
}

.main-footer {
    background-color: var(--theme-color-secondary);
    color: var(--theme-color-primary);
}

[class*=sidebar-light-] .sidebar a,
.nav-pills div.nav-link,
[class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link {
    color: var(--theme-color-primary);
}

#side_popup_div .side_popup_div_heading {
    background-color: var(--theme-color-secondary) !important;
    color: var(--theme-color-primary) !important;
}

#side_popup_div .side_popup_div_heading svg {
    color: var(--theme-color-primary) !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    color: var(--theme-color-primary) !important;
}

[class*=sidebar-light] .user-panel {
    border-bottom: 1px solid var(--theme-color-primary) !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--theme-color-primary) !important;
    border-color: var(--theme-color-primary) !important;
}

.MTableToolbar-title-79 h6 {
    color: var(--theme-color-primary) !important;
}

.MuiTablePagination-toolbar,
.css-1ptx2yq-MuiInputBase-root-MuiInput-root span,
.css-1ptx2yq-MuiInputBase-root-MuiInput-root input,
.MTablePaginationInner-root-84,
.breadcrumb-item a,
.breadcrumb-item+.breadcrumb-item::before,
.MuiTypography-h6,
.MTablePaginationInner-root-84 span,
.tree-item-icon {
    color: var(--theme-color-primary) !important;
}

.breadcrumb-item.active {
    color: var(--theme-color-primary) !important;
    font-weight: 500 !important;
}

.tree-wrapper .tree-item {
    flex: 1;
}

.profilelog {
    height: 110px;
    width: 110px;
    /* line-height: 110px; */
    line-height: 103px;
    border: 1px solid #e1f4fb;
    border-radius: 100%;
    text-align: center;
    font-size: 3em;
    color: #0f3f7d;
    background-color: #e1f4fb;
    margin: 0px auto;
    margin-bottom: 10px;
}

[aria-label="Delete"] {
    color: #dc3545 !important;
}

.card-header {
    background-color: var(--theme-color-secondary) !important;
}

.card-header .card-title {
    color: var(--theme-color-primary) !important;
    font-weight: 500;
}

.MuiTablePagination-toolbar {
    display: flex !important;
    align-items: baseline !important;
}

span .MuiButtonBase-root:hover {
    border: 0;
    background-color: transparent !important;
}

.standard_variant_multiple svg {
    margin-right: -7px !important;
}

.select__control {
    font-size: 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.select__control input{
    font-size: 16px !important;
}
.select__menu div{
    font-size: 16px !important;
}

.objectClass {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginHeader {
    font-size: 30px;
    font-weight: 700;
}

.inputWidth {
    width: 100%;
}

.loginFormDiv {
    width: 40% !important;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .loginFormDiv {
        width: 100%!important;
    }
    .MuiTablePagination-toolbar p{margin-bottom: 0 !important;}
    .MuiTablePagination-toolbar {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 !important;
    }
    .MuiTablePagination-toolbar div:last-child {
        flex: 1 1 auto!important;
        padding: 0px 15px !important;
    }
}

.select__control {
    border-top: none !important;
    border-bottom: 2px solid black;
    border-right: none !important;
    border-left: none !important;
    border-radius: 0 !important;
}

.select__value-container {
    padding: 0px 0px !important;
}

.select__control:focus {
    border-bottom: 2px solid #1976d2 !important;
    left: 0;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    pointer-events: none;
}

.select__control:after {
    border-bottom: 2px solid #1976d2 !important;
    left: 0;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    pointer-events: none;
}

.nav-link:focus,
.nav-link:hover {
    color: #fff;
}

fieldset {
    min-width: auto !important;
    padding: 10px 15px !important;
    margin: auto !important;
    border-radius:10px !important;
    border: 2px solid var(--theme-color-primary) !important;
    margin-bottom: 10px !important;
}
legend {
    display: block;
    width: auto;
    max-width: 100%;
    padding: 0;
    font-size: 1.2rem;
    margin-bottom:0 !important;
    font-weight: 600 !important;
    line-height: inherit;
    color: var(--theme-color-primary);
    white-space: normal;
}
table.MuiTable-root{
    margin: 1px 10px !important;
}

.MuiInputLabel-shrink {
 font-size: 18px !important;
}
.breadcrumb-item {
    font-size: 18px !important;
}
[class*=sidebar-light-] .nav-sidebar>.nav-item>.nav-treeview {
    background-color: #cae8f3;
    border-radius: 2%;
}