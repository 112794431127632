/* width */
#form-elements::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  #form-elements::-webkit-scrollbar-track {
    background: #efefef; 
  }
   
  /* Handle */
  #form-elements::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius:5px;
  }
  
  /* Handle on hover */
  #form-elements::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

#form-elements {
    width: 20%;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
}
#form-generator {
    width: 80%;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
}