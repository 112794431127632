.containers {
    height: 40vh;
    max-height: 40vh;
    overflow-y: auto;
}
.containers .card-body {
    padding: 5px;
}

.MasterFormDragItem:hover {
    cursor: move;
}