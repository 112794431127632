#side_popup_div.sm {
    width: 420px !important;
    max-width: 420px !important;
    margin-right: -420px;
}

#side_popup_div.md {
    width: 50% !important;
    max-width: 50% !important;
    margin-right: -50%;
}

#side_popup_div.lg {
    width: 100% !important;
    max-width: 77% !important;
    margin-right: -77%;
}

#side_popup_overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
}

#side_popup_div {
    right: 0;
    bottom: 0;
    top: 57px;
    width: 50%;
    z-index: 99;
    position: fixed;
    max-height: 100%;
    background-color: #fff;
    box-shadow: -5px 0px 5px #484848;
    transition: margin-right 0.5s ease-in;
}

#side_popup_div_content {
    overflow-y: scroll !important;
    margin-bottom: 100px !important;
    height: 90vh;
}

#close_side_popup_div {
    cursor: pointer;
}