.sie {
    color:#7e7e7e;
    /* cursor: grab; */
    list-style: none;
    margin-bottom: 10px;
    /* padding: 5px; */
    border: 1px solid #7e7e7e;
    border-radius: 5px;
    font-size: 13px;
}

.sie .action-cons i{
    cursor: pointer;
    margin-left: 5px;
}