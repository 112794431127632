.nav-header {
    background-color: #0000005e;
}

.navName {
    font-size: 30px;
    font-weight: 700;
}

.navMenu {
    color: #fff!important;
    font-weight: 700!important;
}

.header-heading {
    font-size: 40px;
}

.bannerImg {
    /* background-image: url('https://c4.wallpaperflare.com/wallpaper/146/782/552/nature-is-pleased-with-simplicity-and-nature-is-no-dummy-wallpaper-preview.jpg'); */
    background-image: url('../../assets/images/homeImg33.jpg');
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
}

.flexDiv {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100%;
}

.bannerHeading {
    font-size: 70px;
}

.bannerSubHeading {
    font-size: 17px;
    margin-bottom: 15px;
}

.contactBtn {
    background: transparent;
    height: 50px;
    width: 160px;
    border: 3px solid #fff;
    border-radius: 38px;
    font-weight: 600;
    font-size: 20px;
    padding: 5px 25px;
}